/* Brand- Hilton Default/Enterprise Branding */

/* ============================= TYPOGRAPHY ============================= */
@font-face {
  font-family: Hilton Serif;
  font-weight: 400;
  font-display: swap;
  src: url('/modules/assets/fonts/hilton-serif/Hilton-SerifRegular.woff2') format('woff2');
}

@font-face {
  font-family: Hilton Serif;
  font-weight: 500;
  font-display: swap;
  src: url('/modules/assets/fonts/hilton-serif/Hilton-SerifMedium.woff2') format('woff2');
}

@font-face {
  font-family: Hilton Sans;
  font-weight: 400;
  font-display: swap;
  src: url('/modules/assets/fonts/hilton-sans/HiltonSansRegular.woff2') format('woff2');
}

@font-face {
  font-family: Hilton Sans;
  font-weight: 500;
  font-display: swap;
  src: url('/modules/assets/fonts/hilton-sans/HiltonSansMedium.woff2') format('woff2');
}

@font-face {
  font-family: Hilton Sans;
  font-weight: 600;
  font-display: swap;
  src: url('/modules/assets/fonts/hilton-sans/HiltonSansSemiBold.woff2') format('woff2');
}

@font-face {
  font-family: Hilton Sans;
  font-weight: 700;
  font-display: swap;
  src: url('/modules/assets/fonts/hilton-sans/HiltonSansBold.woff2') format('woff2');
}

/* ============================= THEMING ============================= */
.theme-hi-refresh {
  --color-primary: 0, 47, 97;
  --color-primary-alt: 0, 22, 59;
  --color-secondary: 0, 156, 222;
  --color-tertiary: 132, 35, 94;
  --color-tertiary-alt: 96, 27, 69;
  --color-quarternary: 229, 114, 0;
  --color-quarternary-alt: 197, 98, 0;
  --color-brand: 0, 47, 97;
  --color-brand-alt: 0, 22, 59;
  --color-hilton: 0, 47, 97;
  --color-hilton-active: 0, 22, 59;
  --color-hilton-alt: 49, 113, 143;
  --color-bg-primary: 235, 244, 247;
  --color-text: 18, 18, 18;
  --color-text-alt: 102, 102, 102;
  --color-text-inverse: 255, 255, 255;
  --color-highlight: 6, 147, 126;
  --color-accent: 49, 113, 143;
  --color-bg-light: 240, 233, 230;
  --color-bg-dark: 0, 47, 97;

  /* Font and Heading styles */
  --font-headline: 'Hilton Serif';
  --font-sans: 'Hilton Sans';
  --font-headline-weight: 500;
  --color-text-heading: var(--color-primary);

  /* property-ui theme */
  --color-hi-primary: 255, 255, 255;
  --color-hi-primary-contrast: 18, 18, 18;
  --color-hi-secondary: 241, 241, 241;
  --color-hi-secondary-contrast: 18, 18, 18;
  --color-hi-tertiary: 0, 47, 97;
  --color-hi-tertiary-contrast: 255, 255, 255;
  --color-hi-accent: 0, 47, 97;

  /* Brand Button styles */
  --btn-base-font-weight: 500;
  --btn-bg-hover-color-dark: var(--color-bg-light);
  --btn-text-hover-color-dark: var(--color-primary);

  --btn-text-hover-color-dark-solid: var(--color-primary);
  --btn-bg-hover-color-dark-solid: var(--color-bg-light);

  /* Shop Form Buttons */
  --shop-form-btn-text-color: var(--color-primary);
  --shop-form-btn-border-color: var(--color-primary);
  --shop-form-btn-text-hover-color: var(--color-primary-alt);
  --shop-form-btn-border-hover-color: var(--color-primary-alt);

  /* Header Navigation */
  --nav-item-text-font-size: 1rem;
  --nav-main-link-font-weight: 400;
  --header-item-hover-color: var(--color-bg-light);

  /* Image Headliner */
  --image-headliner-font-family: var(--font-sans);
  --image-headliner-text-color-light: var(--color-text);

  /* Video Control Styles */
  --slider-thumb-color-dark: var(--color-primary);
  --slider-gradient-on-color-dark: var(--color-primary);

  /* Image border radius for all images */
  --image-corner-radius: 0.5rem;

  /* Image Captions */
  --caption-main-font-weight: 500;

  /* Tabbed Component Styles*/
  /* Horizontal */
  --horizontal-tabbed-list-button-active-bg-no-icon-default: var(--color-bg-light);
  --horizontal-tabbed-list-button-active-bg-no-icon-light: var(--color-bg);
  --horizontal-tabbed-list-button-active-bg-no-icon-dark: var(--color-bg-light);
  --horizontal-tabbed-list-button-active-text-color-no-icon-dark: var(--color-primary);
  --horizontal-tabbed-list-scrollable-border-b-color: var(--color-primary);
  --horizontal-tabbed-list-scrollable-border-b-color-light: var(--color-primary);
  --horizontal-tabbed-list-button-active-border-b-color-light: var(--color-accent);
  --horizontal-tabbed-list-button-active-border-b-color-dark: var(--color-highlight);
  --horizontal-tabbed-list-scrollable-border-b-color-dark: var(--color-highlight);
  /* Vertical */
  --vertical-tabbed-accordion-border-color: var(--color-primary);
  --vertical-tabbed-accordion-collapsed-bg-color-default: var(--color-bg-light);
  --vertical-tabbed-accordion-border-color-light: var(--color-primary);
  --vertical-tabbed-accordion-collapsed-bg-color-light: var(--color-bg);
  --vertical-tabbed-accordion-collapsed-bg-color-dark: var(--color-bg-light);
  --vertical-tabbed-accordion-text-color-dark: var(--color-primary);
  --vertical-tabbed-accordion-border-color-dark: var(--color-highlight);
  --vertical-tabbed-accordion-expanded-border-color-dark: var(--color-highlight);
  --vertical-tabbed-button-inactive-bg: var(--color-bg-light);
  --vertical-tabbed-button-inactive-border-color: var(--color-primary);
  --vertical-tabbed-button-inactive-text-color: var(--color-primary);
  --vertical-tabbed-button-active-border-color: var(--color-primary);
  --vertical-tabbed-button-active-text-color: var(--color-primary);
  --vertical-tabbed-panel-border-color: var(--color-primary);
  --vertical-tabbed-button-inactive-bg-light: var(--color-bg);
  --vertical-tabbed-button-inactive-border-color-light: var(--color-primary);
  --vertical-tabbed-button-inactive-text-color-light: var(--color-primary);
  --vertical-tabbed-button-active-border-color-light: var(--color-primary);
  --vertical-tabbed-button-active-text-color-light: var(--color-primary);
  --vertical-tabbed-button-active-before-bg-light: var(--color-accent);
  --vertical-tabbed-panel-border-color-light: var(--color-primary);
  --vertical-tabbed-button-inactive-bg-dark: var(--color-bg-light);
  --vertical-tabbed-button-inactive-border-color-dark: var(--color-highlight);
  --vertical-tabbed-button-inactive-text-color-dark: var(--color-primary);
  --vertical-tabbed-button-active-border-color-dark: var(--color-highlight);
  --vertical-tabbed-button-active-before-bg-dark: var(--color-highlight);
  --vertical-tabbed-panel-border-color-dark: var(--color-highlight);

  /* DynamicGrid WoM */
  --dynamic-grid-item-wom-header-font-family: var(--font-sans);
  --dynamic-grid-item-wom-header-font-weight: 500;
  --dynamic-grid-item-wom-header-padding-bottom: 8px;
  --dynamic-grid-item-wom-header-padding-top: 8px;

  /* DynamicGrid */
  --dynamic-grid-item-header-font-family: var(--font-headline);
  --dynamic-grid-item-header-font-weight: 500;
  --dynamic-grid-item-header-font-size: 20px;
}

@screen lg {
  .theme-hi-refresh {
    /* Patchwork Grid Row */
    --patchwork-grid-row-headline-font-size: 2.25rem;
    --patchwork-grid-row-headline-line-height: 2.5rem;
  }
}

.theme-hi-refresh h1,
.theme-hi-refresh h2,
.theme-hi-refresh h3,
.theme-hi-refresh h4,
.theme-hi-refresh h5,
.theme-hi-refresh h6 {
  font-variant-ligatures: common-ligatures;
}

.theme-hi h1,
.theme-hi h2,
.theme-hi h3,
.theme-hi h4,
.theme-hi h5,
.theme-hi h6 {
  font-variant-ligatures: common-ligatures;
}
